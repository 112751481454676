import {
  SEL,
  SEL_DATE,
  CHANGE_PATH,
  CHANGE_CART,
  SCROLL,
  EMAIL,
  REF,
} from "./types.js";

export const selLocAction = (payload) => {
  if (payload) {
    payload.updatedAt = new Date();
  }
  return { type: SEL, payload };
};
export const selDateAction = (payload) => ({ type: SEL_DATE, payload });
export const selPathAction = (payload) => ({ type: CHANGE_PATH, payload });
export const selCartAction = (payload) => ({ type: CHANGE_CART, payload });
export const scrollToAction = (payload) => ({ type: SCROLL, payload });
export const emailAction = (payload) => ({ type: EMAIL, payload });
export const refAction = (payload) => ({ type: REF, payload });
