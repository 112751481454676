import { firebase } from "../config/fbConfig";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions(firebase, process.env.DEV_REGION);

export const verifyTxn = (data, type, verifyCallback) => {
  console.log("emailed verifyAcc ");

  httpsCallable(
    functions,
    type
  )(data).then((response) => {
    if (verifyCallback) verifyCallback(response);
  });
};
